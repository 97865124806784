const polecamList=[
    {
        id:1,
        name:"Alandia",
        description:"Dekoracje sal, plenerow, kosciolow Florystyka slubna",
        link:"https://alandia-dekoracje-slub-wesele.manifo.com",
    },
    {
        id:2,
        name:"Twoja Firma",
        description:"Tutaj moze sie wyswietlac twoja firma Zapraszam do kontaktu",
        link:"",
    },
]
const Content = () => {
    const polecam=polecamList.map(item=>(
        <div className="contentPolecamDiv" key={item.id}>
            <h1 className="contentPolecamH1"><a href={item.link} target="_blank" rel="noreferrer">{item.name}</a></h1>
            <p className="contentPolecamP">{item.description}</p>
        </div>
    ))
    return ( 
        <>
        <div id="content">
            <fieldset id="contentBox">
                <legend>Polecam</legend>
                {polecam}
            </fieldset>
        </div>
        </>
     );
}
 
export default Content;