import xico from '../Images/x.png';
import listico from '../Images/list.png';
const list=[
    {name:"O mnie", path:"#mainText"},
    {name:"Cennik", path:"#contactHrefCennik"},
    {name:"Kontakt", path:"#contactHrefKontakt"},
    {name:"Galeria", path:"#galleryDiv"},
    {name:"Polecam", path:"#content"},
]


const showNavList=()=>{
    const showNav = document.getElementById("navLinks")
    if (showNav) {
        showNav.style.height="100%";
    }
}
const hideNavList=()=>{
    const hideNav = document.getElementById("navLinks")
    if(hideNav){
        hideNav.style.height="0";
    }
}
const Nav = () => {
    const menu=list.map(item=>(
        <div key={item.name}>
            <a className="navLi" href={item.path}>{item.name}</a>
        </div>
    ))
    return ( 
        <>
        <script>
            {}
        </script>
        <div id="navbar">
            <div className="nav-links navActive" id="navLinks">
                <img src={xico} className="naviBtn" id='hideNavibtn' alt='buttonHide' onClick={hideNavList}/>
                {menu}
            </div>
            <img src={listico} className="naviBtn" id='showNavibtn' alt='buttonShow' onClick={showNavList}/>
            
        </div>
        </>
     );
}
 
export default Nav;