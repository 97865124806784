import React, { Component } from 'react'
import image1 from "../Images/galleryImages/1.jpg"
import image2 from "../Images/galleryImages/2.jpg"
import image3 from "../Images/galleryImages/3.jpg"
import image4 from "../Images/galleryImages/4.jpg"
import image5 from "../Images/galleryImages/5.jpg"
import image6 from "../Images/galleryImages/6.jpg"
import image7 from "../Images/galleryImages/7.jpg"
import image8 from "../Images/galleryImages/8.jpg"
import image9 from "../Images/galleryImages/9.jpg"
import image10 from "../Images/galleryImages/10.jpg"
import image11 from "../Images/galleryImages/11.jpg"

import video1 from "../Videos/galleryVideos/1.mp4"
import video2 from "../Videos/galleryVideos/2.mp4"
import video3 from "../Videos/galleryVideos/3.mp4"
import video4 from "../Videos/galleryVideos/4.mp4"
import video5 from "../Videos/galleryVideos/5.mp4"
import video6 from "../Videos/galleryVideos/6.mp4"
import video7 from "../Videos/galleryVideos/7.mp4"

// const btnMouseEnter=()=>{
//     document.getElementById("galleryShowBtn").textContent = "Pokaz";
// }
// const btnMouseOver=()=>{
//     document.getElementById("galleryShowBtn").textContent = "Filmy i Zdjecia";
// }

class Gallery extends Component {
    state ={
   
    } 
    playVideos = (event, name) => {
        const player = document.getElementById(name);

        if (player) {
            if (player.paused === false) {
                player.pause();
            } else {
                player.play();
            }
        }
    }
    
    render() { 
        
        const delay = (delayInms) => {
            return new Promise(resolve => setTimeout(resolve, delayInms));
          };
        const listImages=[
            {name:"1", path: image1},
            {name:"2", path: image2},
            {name:"3", path: image3},
            {name:"4", path: image4},
            {name:"5", path: image7},
            {name:"6", path: image6},
            {name:"7", path: image5},
            {name:"8", path: image8},
            {name:"9", path: image9},
            {name:"10", path: image10},
            {name:"11", path: image11},
        ]
        const menuImage=listImages.map(item=>(
            <div className="galleryImageDivSource" key={item.name}>
                <img className="galleryImageSource" src={item.path} alt={item.name}></img>
            </div>
        ))



        const listVideos=[
            {name:"video1", path: video1},
            {name:"video2", path: video2},
            {name:"video3", path: video3},
            {name:"video4", path: video4},
            {name:"video5", path: video5},
            {name:"video6", path: video6},
            {name:"video7", path: video7},
        ]
        const menuVideos=listVideos.map(item=>(
            <div onClick={(event) => this.playVideos(event, item.name)}  className="galleryImageDivSource" key={item.name}>
                <video id={item.name} className="galleryImageSource"><source src={item.path} type="video/mp4"></source></video>
            </div>
        ))


        const galleryChangeSourceImg= async ()=>{
            var video=document.getElementById("gallerySourceVideos");
            var img=document.getElementById("gallerySourceImages");
            var shape=document.getElementById("galleryShapeDivId1");
            var shape2=document.getElementById("galleryShapeDivId2");
    
            shape2.classList.remove("galleryActiveBtn2")
            shape.classList.add("galleryActiveBtn1")

            video.classList.remove("galleryDisplay")
            video.classList.add("galleryNotDisplay")
            let delays = await delay(1100);
            
            img.classList.remove("galleryNotDisplay")        
            img.classList.add("galleryDisplay")
            
        }
        const galleryChangeSourceVideos= async ()=>{
            var video=document.getElementById("gallerySourceVideos");
            var img=document.getElementById("gallerySourceImages");
            var shape=document.getElementById("galleryShapeDivId1");
            var shape2=document.getElementById("galleryShapeDivId2");
            
            shape.classList.remove("galleryActiveBtn1")
            shape2.classList.add("galleryActiveBtn2")

            img.classList.remove("galleryDisplay")
            img.classList.add("galleryNotDisplay")
            let delays = await delay(1100);
            
            video.classList.remove("galleryNotDisplayVideo")
            video.classList.remove("galleryNotDisplay")
            video.classList.add("galleryDisplay")
            
        }
        return (
            <div id="galleryDiv">
            <div id="galleryHeaderContainer">
                <div id="galleryShapeDivId1" onClick={galleryChangeSourceImg} className="galleryBtnShape galleryBtnShape1 galleryActiveBtn1">
                    <p id="galleryImages" className="galleryChangeButtons " >Zdjecia</p>
                </div>
                <h2 id="galleryH2">Galeria</h2>
                <div id="galleryShapeDivId2" onClick={galleryChangeSourceVideos} className="galleryBtnShape galleryBtnShape2">
                    <p id="galleryVideos" className="galleryChangeButtons " >Filmy</p>
                </div>
            </div>
            <div id="gallerySourceImages" className="galleryDisplay">
                {menuImage}
            </div>
            <div id="gallerySourceVideos" className="galleryNotDisplayVideo">
                {menuVideos}
            </div>
            {/* <p id="galleryBtnMore" className="galleryChangeButtons">Pokaz kolejne</p> */}

        </div>
        );
    }
}
 
export default Gallery;