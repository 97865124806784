import moneyImage from "../Images/money.png"
import djImage from "../Images/dj.png"
import phoneImage from "../Images/phone.png"
import tiktokImage from "../Images/tiktok.png"
import instagramImage from "../Images/instagram.png"
import facebookImage from "../Images/facebook.png"
const Contact = () => {
    return ( 
        <>
        <div id="contact">
            <div className="contactBlocks">
                <div className="contactImageBlocks">
                    <img src={moneyImage} alt="moneyImage"></img>
                </div>
                <div className="contactContentBlocks" id="contactHrefCennik">
                    <h2>Cennik</h2>
                    <p>Do ustalenia zaleznie od preferencji i wymagan klienta.<br></br>
                     <br></br>Ceny zaczynaja sie juz od 500 zl</p>
                </div>
            </div>
            <div className="contactBlocks">
                <div className="contactImageBlocks">
                    <img src={djImage} alt="djImage"></img>
                </div>
                <div className="contactContentBlocks">
                    <h2>Sprzet</h2>
                    <p>Nagłosnienie z mikrofonami, wytwornice iskier oraz dymu,
                        ruchome glowy, lasery, derby, statywy, tuby ledowe, projektor,
                        konsole DJ, kolorofon, stroboskop oraz tablice led</p>
                </div>
            </div>
            <div className="contactBlocks">
                <div className="contactImageBlocks">
                    <img src={phoneImage} alt="phoneImage"></img>
                </div>
                <div className="contactContentBlocks" id="contactHrefKontakt">
                    <h2>Kontakt</h2>
                    <p>
                        <a className="contactHref" href="tel:+48577000784"> Telefon: <br></br> +48 577 000 784<br></br></a>
                        <a className="contactHref" href="tel:+48516338775"> lub:<br></br> +48 516 338 775<br></br></a><br></br>
                        <a className="contactHref" href="mailto:martinvankrause@onet.pl">E-mail: <br></br> martinvankrause @onet.pl</a>
                        <div id="contactSocialsImages">
                            <a href="https://www.instagram.com/martin_van_krause?igsh=ZHk5M2U5bXZoaTJo" target="_blank" rel="noreferrer"><img className="contactSocialIco" src={instagramImage} alt="social"></img></a>
                            <a href="https://www.facebook.com/profile.php?id=61558470902186" target="_blank" rel="noreferrer"><img className="contactSocialIco" src={facebookImage} alt="social"></img></a>
                            <a href="https://www.tiktok.com/@martinvankrause?_t=8liHR1FEwRF&_r=1" target="_blank" rel="noreferrer"><img className="contactSocialIco" src={tiktokImage} alt="social"></img></a>
                        </div>
                    </p>
                </div>
            </div>
        </div>
        </>
     );
}
 
export default Contact;