const Footer = () => {
    return ( 
        <>
        <div id="footer">
            <p id="footerP">Martin Van Krause <br></br>+48 577 000 784 - martinvankrause@onet.pl</p>
        </div>
        </>
     );
}
 
export default Footer;