import React, { Component } from 'react'
import "./App.css"
import './animate.css';
import Nav from './Components/Nav';
import Header from './Components/Header';
import MainText from './Components/MainText';
import Content from './Components/Content';
import background from "./Videos/background2.mp4"
import Footer from './Components/Footer';
import Contact from './Components/Contact';
import Gallery from './Components/Gallery';

class App extends Component {
  state = {  } 
  render() { 
    return (
      <div id='app'>
        <video id='appVideo' autoPlay muted loop >
          <source src={background} id='backgroundVideo' type='video/mp4'></source>
        </video>
        <Nav/>
        <Header/>
        <MainText/>
        <Contact/>
        <Gallery/>
        <Content/>
        <Footer/>
      </div>
    );
  }
}
 
export default App;