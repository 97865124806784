import martin from "../Images/martin1.png"
import martin2 from "../Images/martin2.png"
import martin3 from "../Images/martin3.png"
const MainText = () => {
    return ( 
        <>
        <div id="mainText">
            <div className="mainTextBlock" id="mainTextBlock1">
                <div className="MainTextImageGroup">
                    <img src={martin} alt="martin" className="mainTextImage"></img>
                </div>
                <div className="mainTextContent">
                    <p><h1 className="mainTextContentH1">DJ Martin Van Krause</h1>  utalentowany artysta muzyczny, ktory potrafi zaskoczyc kazdego swoim niezwyklym talentem i umiejetnosciami.
                    Jego niezrownana energia i pasja do muzyki sprawiaja, ze jego wystepy sa zawsze pelne ekscytujacej atmosfery i niezapomnianych wrazen dla publicznosci.</p>
                </div>
            </div>
            <div className="mainTextBlock" id="mainTextBlock2">
                <div className="MainTextImageGroup">
                    <img src={martin2} alt="martin2" className="mainTextImage"></img>
                </div>
                <div className="mainTextContent">
                    <p><h1 className="mainTextContentH1">Szeroki repertuar muzyczny</h1> obejmuje roznorodne gatunki, takie jak house, techno, hip-hop, pop oraz lata 60, 70, 80, 90 co sprawia, ze potrafi zadowolic kazdego sluchacza.
                    Martin potrafi doskonale odczytac nastroje publicznosci i dostosowac swoj repertuar do ich preferencji, zapewniajac niezapomniane doznania muzyczne.</p>
                </div>
            </div>
            <div className="mainTextBlock" id="mainTextBlock3">
                <div className="MainTextImageGroup">
                    <img src={martin3} alt="martin3" className="mainTextImage"></img>
                </div>
                <div className="mainTextContent">
                    <p><h1 className="mainTextContentH1">Zagra na kazdej uroczystosci</h1> od szkolnych dyskotek po wszelkiego rodzaju imprezy okolicznosciowe.
                    Oprocz standardowego wyposazenia jakim jest oswietlenie i naglosnienie posiada szeroki asortyment dodatkow w ktore cały czas inwestuje by sprostac oczekiwaniom klienta</p>
                </div>
            </div>
        </div>
        </>
     );
}
 
export default MainText;