//import logo1 from "../Images/logo.jpg"
import logo2 from "../Images/logo2.jpg"
const Header = () => {
    return ( 
        <>
        <div id="header">
            <img id="headerLogo" src={logo2} alt="logo"></img>
            <h1 id="headerH1">Martin Van Krause</h1>
            <h2>Profesjonalny DJ który obudzi kazda impreze!</h2>
        </div>
        </>
     );
}
 
export default Header;